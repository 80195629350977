import NotificationManager from "react-notifications/lib/NotificationManager";
import { postData } from "../global";



export default async function  checkLogin(data)
{
    await postData("/authentication",data)
    .then(res=>{
        console.log(res);
        localStorage.setItem("jwt",res.data.jwt)
        window.location.replace("/")
    })
    .catch(err=>{
        NotificationManager.error("username or password is incorrect")
    })
}

