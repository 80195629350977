import React, { forwardRef, useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import  {postDataWithHeaderToast, deleteDataToast } from '../../global';
import CKEditor from 'ckeditor4-react';
import { deleteBook, getBookList, saveBookData, saveBookFile, updateBook } from '../../services/BookService';
import MyBackdrop from '../elements/MyBackdrop';
import { Button, Drawer, makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Image } from '@material-ui/icons';
import MyDrawer from '../header/Drawer';
import getEnumsByCategory, { saveEnum } from '../../services/EnumService';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        margin: theme.spacing(1),
        padding: theme.spacing(3),
    },
    gridItem: {
        padding: 20
    },
    textField: {
        margin: 20,
        maxWidth:1500,
    },
    common: {
        margin: 20,
        maxWidth:1500,

    },
    input: {
        display: 'none',
        maxWidth:1000,
    },
    formControl: {
        margin: theme.spacing(1),
        width:250,
        margin: 20,
        maxWidth:1500,
    },
}))
export default function AddLanguage() {
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    }
    const [libraries, setLibraries] = useState([]);
    const jwt = localStorage.getItem("jwt");
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [imgFile,setImgFile]=useState();

    const getLibraries = async () => {
        const response = await getEnumsByCategory("lang");
        setLibraries(response);
    }

    const handleChangeImg=async(id,file,type)=>{
        setOpen(true);

        if (file!==null){
            saveBookFile(id,file,type)
            .then(res=>{
                setOpen(false);
            })
            .catch(err=>{
                setOpen(false);
            })
            }
    }

    useEffect(() => {
        getLibraries();
    }, [])

    return (

      
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MyDrawer />

        <main  className={classes.content}>
            <div className={classes.toolbar}/>

                                    <MaterialTable
                                        icons={tableIcons}
                                        title="Languages"
                                        options={{
                                            exportButton: true,
                                            // filtering: true,
                                            headerStyle: {
                                                backgroundColor: '#2196F3',
                                                color: '#FFF'
                                            },
                                            cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 300},
                                            grouping: true,
                                            pageSizeOptions: [10, 15, 30],
                                            pageSize: 10
                                        }
                                        
                                    }
                                    editable={{
                                        onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                const dataUpdate = [...libraries];
                                                const index = oldData.tableData.id;
                                                dataUpdate[index] = newData;
                                                const libraryObject = { ...newData }
                                                console.log(libraryObject);
                                                setLibraries([...dataUpdate]);
                                                saveEnum( libraryObject, "succesfully updated ")
                                                
                                                resolve();
                                            }, 1000)
                                        }),
                                        onRowAdd: newData =>
                                        new Promise((resolve, reject) => {
                                          setTimeout(() => {
                                            setLibraries([...libraries, newData]);
                                            const lang={...newData,category:"lang"}
                                            saveEnum(lang);
                                            resolve();
                                          }, 1000)
                                        }),
                                    }}
                                    columns={[
                                        {title:'ID',field:'id',editable:false},
                                        { title: 'Status', field: 'status', lookup: { true: 'enable', false: 'disable' } },
                                        { title: 'Name', field: 'name' },
                                        { title: 'Value', field: 'value' },
                                       
                                        
                                    ]}
                                    data={libraries}
                                    
                                    />
                                <MyBackdrop open={open}/>
            </main>
        </MuiPickersUtilsProvider>
    )
}


