import { getDataWithHeader, postDataWithHeaderToast } from "../global";

export default  async function getEnumsByCategory(category)
{
    const response =await getDataWithHeader("/enum/findAllByCategory/"+category);
    return response.data;
}

export async function saveEnum(data)
{
   await postDataWithHeaderToast("/enum/save",data,"successfully added");
}