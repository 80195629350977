
import React, { useEffect, useState } from 'react'
import 'react-notifications/lib/notifications.css';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'
import AddBook from './components/book/AddBook';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { NotificationContainer } from 'react-notifications';
import AddAudioBook from './components/audio/AddAudio';
import BookList from './components/list/BookList';
import Login from './components/login/Login';
import { authenticate } from './global';
import AddLanguage from './components/lang/AddLanguage';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',

  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))


const ProtectedRoute
  = ({ isAllowed, ...props }) =>
    isAllowed
      ? <Route {...props} />
      : <Redirect to="/login" />;


function App() {
  const [isAllowed, setIsAllowed] = useState(true)

  const classes = useStyles();
  const [prefersDarkMode,setPrefersDarkMode] = useState(true);

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
          primary: blue,
          // background: {
          //   default: "#222222"
          // },
        },

      }),
    [prefersDarkMode],
  );

  useEffect(()=>{
    checkAuthenticate()
  },[])

const   checkAuthenticate=async()=>{
  const response=await authenticate();

  setIsAllowed(response);
}

  return (
    <Router>
      <Route  path="/login" component={Login} />
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <ProtectedRoute exact isAllowed={isAllowed} path="/" component={BookList} />
          <ProtectedRoute isAllowed={isAllowed} path="/addbook" component={AddBook} />
          <ProtectedRoute isAllowed={isAllowed} path="/addaudiobook" component={AddAudioBook} />
          <ProtectedRoute isAllowed={isAllowed} path="/addlanguage" component={AddLanguage} />
        </div>
      </ThemeProvider>
      <NotificationContainer />
    </Router>
  );
}

export default App;
