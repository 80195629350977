import { getDataWithHeader, postDataWithHeader,postDataWithHeaderToast,postFile, postFileToast, deleteDataToast, putDataWithHeaderToast} from "../global";


export default async function getLanguages()
{
    const response= await getDataWithHeader("/enum/findAllByCategoryActive/lang")
    return response.data;
}


export async function saveBook(data,imgFile,bookFile)
{
     await postDataWithHeader("/library/save",data)
    .then(async res=>{
        const fileId=res.data.id;
        let fd = new FormData();
        fd.append('fileId', fileId);
        fd.append('fileImg', imgFile)
        fd.append('bookFile', bookFile)
        await postFileToast("/library/uploadFile", fd,"succesfully saved")
    })
}

export async function saveBookFile(fileId,file,type)
{

        let fd = new FormData();
        fd.append('fileId', fileId);
console.log(type)
        if(type=="png"){
            fd.append('fileImg', file)
            await postFileToast("/library/uploadImgFile", fd,"succesfully saved")
        }
        else if (type=="bookFile")
        {
            fd.append('bookFile', file)
            await postFileToast("/library/uploadBookFile", fd,"succesfully saved")
        }
       
}

export async function getBookList()
{
    const response= await getDataWithHeader("/library/findAll")
    return response.data;
   
}

export async function deleteBook(id)
{
    const response= await deleteDataToast("/library/delete/"+id,"succesfully deleted!")
   
}


export async function updateBook(data)
{
    data.imgUrl=null;
     await putDataWithHeaderToast("/library/update",data,"successfully updated")
}

