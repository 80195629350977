import { Button, FormControl, InputLabel, LinearProgress, makeStyles, Select, TextField } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react'
import { AddAPhoto, Book } from '@material-ui/icons';
import getLanguages, { saveBook } from '../../services/BookService';
import MyBackdrop from '../elements/MyBackdrop';
import MyDrawer from '../header/Drawer';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',

    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        margin: theme.spacing(1),
        padding: theme.spacing(3),
    },
    gridItem: {
        padding: 20
    },
    textField: {
        margin: 20,
        maxWidth:1500,
    },
    common: {
        margin: 20,
        maxWidth:1500,

    },
    input: {
        display: 'none',
        maxWidth:1000,
    },
    formControl: {
        margin: theme.spacing(1),
        width:250,
        margin: 20,
        maxWidth:1500,
    },
}))
export default function AddAudioBook() {

    const [publishedDate, setPubishedDate] = useState(Date.now());
    const [imgProgressShow, setImgProgressShow] = useState(false)
    const [imgProgressValue, setImgProgressValue] = useState(0)
    const [bookName, setBookName] = useState("");
    const [imgName, setImgName] = useState("");
    const [title, setTitle] = useState("");
    const [textDesc, setTextDesc] = useState("");
    const [authorName, setAuthorName] = useState("");
    const [textAuthor, setTextAuthor] = useState("");
    const [imgFile, setImgFile] = useState(null);
    const [bookFile, setBookFile] = useState(null);
    const [lang, setLang] = useState(10)
    const classes = useStyles();
    const [languages,setLanguages]=useState([]);
    const [open,setOpen]=useState(false);

    const handleDateChange = (date) => {
        setPubishedDate(date)
    }

    const handleChangeImg = (file) => {
        if (file!==null){
        setImgName(file.name);
        setImgFile(file);
        }
    }

    const handleChangeBook = (file) => {
        if(file!==null)
        {
            setBookName(file.name);
            setBookFile(file);
        }
    }

    const handleChange = (event) => {
        setLang(event.target.value);
    };

    const getInitials=async()=>{
        const response=await getLanguages();
        setLanguages(response);
        console.log(response);
    }

    useEffect(()=>{
        getInitials();
    },[])


    const handleSubmit=async()=>{
        const year=new Date(publishedDate);
        const bookObj={
            title,
            textDesc,
            createDate:publishedDate,
            year:year.getFullYear(),
            lang:{id:lang},
            authorName,
            textAuthor,
            status:1,
            type:{id:2}
        }
        setOpen(true);
        await saveBook(bookObj,imgFile,bookFile)
        .then(res=>{
            setOpen(false);
            window.location.reload();
        })
        .catch(res=>{
            setOpen(false)
        })
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MyDrawer/>
            <main  className={classes.content}>
                <div className={classes.toolbar} />

                <h2>Add new audio book</h2>

                        <TextField  className={classes.textField} fullWidth name="title" value={title} InputLabelProps={{ shrink: title == "" ? false : true }} onChange={e => setTitle(e.target.value)} label="Title" variant="outlined" />
                        <TextField multiline rowsMax={5} rows={4} name="description" value={textDesc} InputLabelProps={{ shrink: textDesc == "" ? false : true }} onChange={e => setTextDesc(e.target.value)} className={classes.textField} fullWidth name="desc" label="Description" variant="outlined" />
                        <br/>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Published date"
                            format="MM/dd/yyyy"
                            value={publishedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            className={classes.common}
                            />
                        <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="filled-age-native-simple">Select language</InputLabel>
                            <Select
                                native
                                value={lang}
                                onChange={handleChange}
                                label="Language"
                                inputProps={{
                                    name: 'lang',
                                    id: 'filled-age-native-simple',
                                }}
                                >
                                 {
                                     languages.map(lang=>{
                                     return <option  key={lang.id} value={lang.id}>{lang.name}</option>
                                     })
                                  
                                }
                            </Select>
                        </FormControl>
                        <br />

                        <input
                            accept="pdf/*"
                            className={classes.input}
                            id="bookFile"
                            multiple
                            type="file"
                            onChange={(event) => { handleChangeBook(event.target.files[0]) }}
                            />
                            <br/>
                        <label htmlFor="bookFile" style={{ alignSelf: "flex-start" }}>
                            <Button variant="contained" color="primary" className={classes.common}
                                startIcon={<Book />}
                                component="span">
                                Add file
                        </Button>
                        </label>
                        <span>{bookName}</span>
                        <br />


                    {/* right side */}

                        <TextField  className={classes.textField} InputLabelProps={{ shrink: authorName == "" ? false : true }} label="" name="authorName" value={authorName} onChange={e => setAuthorName(e.target.value)} fullWidth name="author" label="Author full name" variant="outlined" />
                        <TextField multiline rowsMax={5} rows={4} InputLabelProps={{ shrink: textAuthor == "" ? false : true }} name="textAuthor" value={textAuthor} onChange={e => setTextAuthor(e.target.value)} className={classes.textField} fullWidth name="aboutauth" label="About author" variant="outlined" />
                        <br/>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="imgFile"
                            multiple
                            type="file"
                            onChange={event => { handleChangeImg(event.target.files[0]) }}
                            />
                        <label htmlFor="imgFile">
                            <Button variant="contained" color="primary" className={classes.common}
                                startIcon={<AddAPhoto />}
                                component="span">
                                Select cover
                        </Button>
                            <span>{imgName}</span>
                        </label>
                        {
                            imgProgressShow
                            ? <LinearProgress className={classes.common} style={{ height: 20 }} variant="buffer" value={imgProgressValue} valueBuffer={imgProgressValue + 5} />
                            : null
                        }
                        <br />
                        <br />
                        <br />
                        <br />
                      
                        <Button onClick={handleSubmit} fullWidth variant="contained" size="large" style={{fontSize:20}} color="primary" className={classes.common}
                            // startIcon={<Save  size={75} />}
                            component="span">
                            Save
                        </Button>
                       
                 <MyBackdrop open={open}/>
            </main>
        </MuiPickersUtilsProvider>
    )
}
